exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-application-rules-tsx": () => import("./../../../src/pages/application-rules.tsx" /* webpackChunkName: "component---src-pages-application-rules-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-create-inspecto-account-tsx": () => import("./../../../src/pages/create-inspecto-account.tsx" /* webpackChunkName: "component---src-pages-create-inspecto-account-tsx" */),
  "component---src-pages-ebook-download-tsx": () => import("./../../../src/pages/ebook-download.tsx" /* webpackChunkName: "component---src-pages-ebook-download-tsx" */),
  "component---src-pages-ebook-tsx": () => import("./../../../src/pages/ebook.tsx" /* webpackChunkName: "component---src-pages-ebook-tsx" */),
  "component---src-pages-functions-tsx": () => import("./../../../src/pages/functions.tsx" /* webpackChunkName: "component---src-pages-functions-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

